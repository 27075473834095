"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";


const Intro = () => {
    return (
        <div id="about">
            <section className="bg-contain bg-no-repeat bg-bottom py-10 md:py-20 lg:py-24" style={{ backgroundImage: 'url("/images/about_bg.jpg")' }}>
                <div className="max-w-screen-xl mx-5 lg:mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
                        <div className="relative">
                            <img alt="" src="/images/about_video.jpg" className=" object-cover z-10" width={425} height={575} />
                            <div className="bg-white w-fit absolute top-28 right-10 -z-20"> 
                                <img alt="" src="/images/about-bg.jpeg" className=" object-cover" width={425} height={575} />
                            </div>
                        </div>
                        <div className="lg:pr-6">
                            <div className="flex">
                                <p className="font-semibold text-red-600 text-5xl md:text-7xl lg:text-9xl uppercase self-start pr-8">11</p>
                                <h2 className="font-bold text-black my-3 uppercase">Years of Awesomeness</h2>
                            </div>
                            <p className="text-base md:text-lg font-medium">Box Office Entertainment is a content hub providing services across varied verticals. All your search for anything and everything media and entertainment related – ends here!</p>
                            <p>Be it film production, advertising, animation or digital marketing, we have been able to leave a lasting impact through our work. Our efficient team hails from a variety of backgrounds ranging from creative production to advertising & marketing</p>
                            <p>Blending effective storytelling with cutting-edge technology to meet the modern video production standards is our forte.</p>
                            {/* <div className="mt-6 md:mt-8"><a href="/" className="btn-red rounded-full px-6 py-2 lg:px-10 lg:py-3">About More</a></div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Intro;