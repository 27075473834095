"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const Contactinfo = () => {
  return (
    <div id="contact">
      <section className=" bg-neutral-800 py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-start justify-between text-white">
            <div>
              <h5 className="font-bold mb-8 text-3xl">Contact Us</h5>
              <div className="flex justify-start">
                <FaMapMarkerAlt size="35" className="mr-4 self-start" />
                <div>
                  <h6 className="uppercase my-2">Address</h6>
                  <p className="py-2">
                    <div className="mb-2">Mumbai Head Office</div>
                    105, Stanford Plaza, Opposite Infinity Mall, New Link Road,
                    Andheri West, Mumbai - 400053.
                  </p>
                  <p>
                    <div className="mb-2">Hyderabad</div>
                    101, Plot No.70, Madhapur, HUDA Techno Enclave, HITEC City,
                    Hyderabad, Telangana 500081
                  </p>
                </div>
              </div>
              <hr className="bg-neutral-700 my-6 md:my-8" />
              <div className="flex justify-start">
                <FaPhoneAlt size="25" className="mr-4 self-start" />
                <div>
                  <h6 className="uppercase mb-2">Phone Number</h6>
                  <p>+91 9082808806</p>
                </div>
              </div>
              <hr className="bg-neutral-700 my-6 md:my-8" />
              <div className="flex justify-start">
                <FaEnvelope size="25" className="mr-4 self-start" />
                <div>
                  <h6 className="uppercase mb-2">Email Address</h6>
                  <p>mansi@boxofficeent.com</p>
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7538.540047502957!2d72.834278!3d19.139654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b63b5e88eba9%3A0x54b965a17a60e227!2sKVRG%20ADVISORS!5e0!3m2!1sen!2sin!4v1725455479294!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactinfo;
