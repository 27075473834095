import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/app/Contactinfo.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/app/Coreteam.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/app/Intro.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/app/Ourservice.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/app/Ourwork.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/app/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/boxofficeentertainment.in/nextjs/src/components/Counter.jsx");
