"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Avatar, Blockquote } from "flowbite-react";

const Coreteam = () => {
  return (
    <div
      id="team"
      className="black-tint py-8 md:py-16 lg:py-28 bg-blend-luminosity z-20 before:-z-10 before:bg-opacity-60"
      style={{ backgroundImage: 'url("/images/testimonials_bg.jpg")' }}
    >
      <div className="max-w-screen-lg mx-5 lg:mx-auto">
        <div className="text-center mb-6 md:mb-10 text-white">
          <h2 className="font-bold my-3">Core Team</h2>
          <p>
            At Box Office Entertainment, our strength lies in the collective
            talent and experience of our core team. Each member brings a unique
            perspective and expertise, making us a formidable force in the
            industry. Together, our core team is committed to delivering
            high-quality works.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 items-stretch justify-between text-center">
          <div className="bg-white shadow-xl p-3 md:p-10 rounded-lg border-2 border-gray-200">
            <figure>
              <figcaption className="items-center justify-center">
                <Avatar
                  rounded
                  className="w-16 h-16 md:w-32 md:h-32 rounded-full m-auto mb-4"
                  img="/images/nishant-sapkale.jpg"
                  alt="profile picture"
                />
                <div className="text-center">
                  <h5 className="font-bold text-black mb-0">Nishant Sapkale</h5>
                  <p>Founder & Director</p>
                </div>
              </figcaption>
              <Blockquote>
                <p className="text-sm md:text-base font-medium italic">
                  A visionary leader with a passion for storytelling, Nishant
                  has spearheaded numerous successful projects.
                </p>
              </Blockquote>
            </figure>
          </div>
          <div className="bg-white shadow-xl p-3 md:p-10 rounded-lg border-2 border-gray-200">
            <figure>
              <figcaption className="items-center justify-center">
                <Avatar
                  rounded
                  className="w-16 h-16 md:w-32 md:h-32 rounded-full m-auto mb-4"
                  img="/images/vinita-nair.jpg"
                  alt="profile picture"
                />
                <div className="text-center">
                  <h5 className="font-bold text-black mb-0">Vinita Nair</h5>
                  <p>Co-Founder & Creative Director</p>
                </div>
              </figcaption>
              <Blockquote>
                <p className="text-sm md:text-base font-medium italic">
                  The creative mind behind our films, Vinita is responsible for
                  bringing all the ideas to life.
                </p>
              </Blockquote>
            </figure>
          </div>
          <div className="bg-white shadow-xl p-3 md:p-10 rounded-lg border-2 border-gray-200">
            <figure>
              <figcaption className="items-center justify-center">
                <Avatar
                  rounded
                  className="w-16 h-16 md:w-32 md:h-32 rounded-full m-auto mb-4"
                  img="/images/shyameli-dutta.jpg"
                  alt="profile picture"
                />
                <div className="text-center">
                  <h5 className="font-bold text-black mb-0">Shyameli Dutta</h5>
                  <p>Co-Founder & Executive Producer</p>
                </div>
              </figcaption>
              <Blockquote>
                <p className="text-sm md:text-base font-medium italic">
                  A seasoned professional, Shyameli oversees all aspects of
                  production, ensuring projects are executed with precision and
                  efficiency.{" "}
                </p>
              </Blockquote>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coreteam;
