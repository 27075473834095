"use client";
import Slider from "react-slick";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HeroSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <section className="relative lg:h-screen h-[40vh] overflow-hidden -mt-0 lg:-mt-28">
      <Slider {...settings}>
        {[
          "/images/slide1.jpg",
          "/images/RJ1.jpg",
          "/images/Konkan-Diaries2.jpg",
          "/images/hm_slide1.jpg",
          "/images/hm_slide2.jpg",
          "/images/hm_slide3.jpg",
          "/images/hm_slide4.jpg",
          "/images/hm_slide5.jpg",
          "/images/hm_slide6.jpg",
          "/images/hm_slide7.jpg",
          "/images/hm_slide8.jpg",
          "/images/hm_slide9.jpg",
        ].map((src, index) => (
          <div className="relative lg:h-screen h-[40vh]" key={index}>
            <Image
              src={src}
              alt={`Slide ${index + 1}`}
              fill
              sizes="100vh"
              className="absolute top-0 left-0 object-cover"
            />
          </div>
        ))}
      </Slider>
    </section>
  );
}
