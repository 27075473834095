"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";


const Ourwork = () => {
    return (
        <div id="work">
            <section className="bg-gray-200 py-10 md:pt-20 lg:pt-24">
                <div className="max-w-screen-xl12 mx-5 lg:mx-auto">
                    <div className="text-center mb-6 md:mb-10">
                            <h2 className="font-bold text-black my-3">Our Work</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-1 items-start justify-between text-center">
                        <div>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/cHuJ7dhJuYk?si=0mit62vUCP8NaHQu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/kya_l9qhbB8?si=RM_UNPKFnT4R_Y1L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/PWZqXlmOkuY?si=POlDKtheqDvoBD88" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/IkJT36qO1hU?si=C1_SXH46dqPt46qG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/De8msaWz05M?si=5dkiBzMBzQDwNNhV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/LoySv9XA1hk?si=ha6OhYkB2z1VQUKD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="mt-6 md:mt-8 text-center"><a href="https://www.youtube.com/channel/UCoTPBUZe03v6y-SQ894WMcQ" className="bg-yellow-400 px-8 py-3 lg:px-12 lg:py-4 rounded-full uppercase font-bold text-neutral-900 text-sm">All Videos</a></div>
                </div>
            </section>
        </div>
    );
};

export default Ourwork;