"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Ourservice = () => {
  return (
    <div id="services">
      <section className="bg-gray-200 py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10">
            <h2 className="font-bold text-black my-3">What we do</h2>
            <p>Offering services across various verticals is our forte.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 items-stretch justify-between text-center">
            <div className="p-2">
              <div className="bg-white rounded-sm border border-gray-300 p-2 w-fit m-auto mb-4">
                <img
                  src="/images/ico_production.png"
                  className="object-cover w-16 h-16"
                  alt="Film Production"
                />
              </div>
              <h5 className="font-bold text-black">Film Production</h5>
              <p className="h-36">
                We offer full range of production services, including
                development, scriptwriting, pre-production, casting, directing
                and post-production.{" "}
              </p>
              <p>
                <a
                  href="/film-production/"
                  className="text-neutral-800 hover:text-black font-bold uppercase text-sm"
                >
                  Read More
                </a>
              </p>
            </div>
            <div className="p-2">
              <div className="bg-white rounded-sm border border-gray-300 p-2 w-fit m-auto mb-4">
                <img
                  src="/images/ico_animation.png"
                  className="object-cover w-16 h-16"
                  alt="Animation"
                />
              </div>
              <h5 className="font-bold text-black">Animation</h5>
              <p className="h-36">
                Welcome to 3Pop Studios! We are a full service animation
                production company delivering its expertise in 2D & 3D
                Animation, Design & VFX to clients locally and internationally.
              </p>
              <p>
                <a
                  href="/animation/"
                  className="text-neutral-800 hover:text-black font-bold uppercase text-sm"
                >
                  Read More
                </a>
              </p>
            </div>
            <div className="p-2">
              <div className="bg-white rounded-sm border border-gray-300 p-2 w-fit m-auto mb-4">
                <img
                  src="/images/ico_vfxmotion.png"
                  className="object-cover w-16 h-16"
                  alt="VFX Motion"
                />
              </div>
              <h5 className="font-bold text-black">VFX & Motion Graphics</h5>
              <p className="h-36">
                At 3Pop Studios, apart from 2D & 3D Animation, we also offer VFX
                services! So all your answers for Roto, Paint, MatchMove,
                Rendering, Matte, Compositing etc. ends right here!{" "}
              </p>
              <p>
                <a
                  href="/vfx-motion-graphics/"
                  className="text-neutral-800 hover:text-black font-bold uppercase text-sm"
                >
                  Read More
                </a>
              </p>
            </div>
            <div className="p-2">
              <div className="bg-white rounded-sm border border-gray-300 p-2 w-fit m-auto mb-4">
                <img
                  src="/images/ico_marketing.png"
                  className="object-cover w-16 h-16"
                  alt=""
                />
              </div>
              <h5 className="font-bold text-black">AR & VR</h5>
              <p className="h-36">
                Over the past few years, Box Office Entertainment has ventured
                into the realms of Augmented Reality (AR) and Virtual Reality
                (VR), offering immersive experiences that redefine how audiences
                interact with content.
              </p>
              <p>
                <a
                  href="/ar-and-vr/"
                  className="text-neutral-800 hover:text-black font-bold uppercase text-sm"
                >
                  Read More
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ourservice;
